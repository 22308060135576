// src/components/Header.js
import React from 'react';

const Header = () => {
    return (
        <header style={headerContainerStyle}>
            {/* Tầng trên chứa thanh tìm kiếm */}
            <div style={topHeaderStyle}>
                <input
                    type="text"
                    placeholder="Tìm kiếm..."
                    style={searchInputStyle}
                />
            </div>
            {/* Tầng dưới chứa logo và menu điều hướng */}
            <div style={bottomHeaderStyle}>
                <div style={logoStyle}>
                    <img src="/logo.svg" alt="Logo" style={{height: '40px'}}/>
                </div>
                <nav style={navStyle}>
                    <a href="/" style={linkStyle}>Trang chủ</a>
                    <a href="/products" style={linkStyle}>Sản phẩm</a>
                    <a href="/solutions" style={linkStyle}>Giải pháp</a>
                    <a href="/services" style={linkStyle}>Dịch vụ</a>
                    <a href="/customers" style={linkStyle}>Khách hàng</a>
                    <a href="/partners" style={linkStyle}>Đối tác</a>
                    <a href="/contact" style={linkStyle}>Liên hệ</a>
                </nav>
            </div>
        </header>
    );
};

// Các style cơ bản cho Header hai tầng
const headerContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px'
};

const topHeaderStyle = {
    backgroundColor: '#444',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
};

const searchInputStyle = {
    padding: '5px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    width: '200px'
};

const bottomHeaderStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#333',
    color: '#fff'
};

const logoStyle = {
    fontSize: '24px',
    fontWeight: 'bold'
};

const navStyle = {
    display: 'flex',
    gap: '15px'
};

const linkStyle = {
    color: '#fff',
    textDecoration: 'none',
    fontSize: '16px'
};

export default Header;
