// src/App.js
import React from 'react';
import Header from './components/Header';

function App() {
    return (
        <div>
            <Header/>
            <main style={{ padding: '20px' }}>
                <h2>Welcome to Mi2 JSC</h2>
                <p>Partnering Success </p>
            </main>
        </div>
    );
}

export default App;
